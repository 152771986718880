import { FunctionComponent } from "react";
import {
    FaucetAmount,
    FaucetCurrency,
    FaucetLimit,
    SiteTitle,
} from "../../config";
import { Btn } from "../Btn";

export const Title: FunctionComponent = () => {
    return (
        <div className="text-center flex flex-col gap-4 justify-center items-center relative">
            <img src="/faucet.png" className="max-w-full w-[100px] mb-4" />
            <h1 className="text-5xl font-bold text-black">{SiteTitle}</h1>
            <p>
                The Pegasus faucet is closed for the foreseeable future. Please
                use the LightLink bridge to transfer funds to Pegasus.
            </p>
            <Btn href="https://lightlink.io/bridge">Go to Bridge</Btn>
        </div>
    );
};
