import { FunctionComponent } from "react";

export interface Props {
    onClick?: Function;
    loading?: boolean;
    disabled?: boolean;
    className?: string;
    children?: any;
    href?: string;
}

export const Btn: FunctionComponent<Props> = (props) => {
    return (
        <a
            href={props.href}
            className={`rounded bg-blue-500 text-white px-4 py-2 w-full md:w-[200px] disabled:opacity-50 ${
                props.className || ""
            }`}
            onClick={(e) => {
                if (props.disabled || props.loading) e.preventDefault();
                else if (props.onClick) props.onClick();
            }}
            style={{ opacity: props.disabled || props.loading ? 0.5 : 1 }}
        >
            {props.loading ? "..." : props.children}
        </a>
    );
};
